body {
  margin: 0;
  font-family: Chakra Petch
  , 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0B0B0B;
  /* background-image: url('../src/assets/resources/Noise.svg'); */
}

.maintenance-mode {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  color: rgb(82 249 218 / 1);
}

h1.maintenance-mode {
  font-size: 2rem;
  text-align: center;
  text-shadow: rgb(82, 249, 218) 1px 0px 10px;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  background: #c2c2c4; 
  border-radius: 10px;
}

.bg-img{
    background-image: url('../src/assets/resources/Noise.svg');
    position: relative;
}

p{
  cursor: default;
}

.AstroWrapper {
  max-width: 1744px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  width: calc(100% - 16px);
}

@media only screen and (max-width: 600px) {
  .AstroWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}


/* cursor css */
.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.cursor__outer {
  width: 3.1rem;
  height: 3.1rem;
}
.cursor__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  height: 3px;
  background-color: #181818;
  border-radius: 9999rem;
}
.cursor__dragText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cursor__videoIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cursor__clickToEnter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-btn {
  transform-origin: center center;
  transition: all 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.35s;
}
.play-pause {
  transform-origin: center center;
  transition: all 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}
.is-video-playing .play-btn {
  opacity: 0;
  transform: scale(0);
  transition-delay: 0s;
}
.is-video-playing .play-pause {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition-delay: 0.35s;
}
.rotate-ring {
  transform-origin: center center;
  will-change: transform;
  animation: rotate-ring-cursor 10s infinite linear;
}
@keyframes rotate-ring-cursor {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.white-copy {
  filter: brightness(0) invert(1);
}


/* button */
.btn {
  border: none;
  outline: none;
  color: #FFF;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 12px;
  font-size: 10px;
  letter-spacing: 1px;
  border: solid 2px white;
}
.btn::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  left: 0;
  top: 0;
  border-radius: 10px;
}
/* glow */
.btn::before {
  content: "";
  background: linear-gradient(140deg,#006D59, #008D73, #00A082, #00BF9B, #00F3C5, #024B3E, #4DFFDD, #B8FFF1);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height:  calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
}

@keyframes glowing {
  0% {background-position: 0 0;}
  50% {background-position: 400% 0;}
  100% {background-position: 0 0;}
}

/* hover */
.btn::before {
  opacity: 1;
}

.btn:active:after {
  background: transparent;
}

.btn:active {
  color: #181818E6;
}

.btn:hover {
  color: #1CF9CF;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 50.25%; /* 16:9 aspect ratio (height/width * 100) */
}

.video-container iframe {
  position: absolute; /* Position video within the container */
  top: 0;
  left: 0;
  width: 100%; /* Ensure video fills the container horizontally */
  height: 100%; /* Maintain aspect ratio */
}


.scrollcolorprimary::-webkit-scrollbar-thumb {
  background: #1CF9CF; 
}
.scrollcolorprimary::-webkit-scrollbar {
  width: 4px;
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox container */
.custom-checkbox {
  display: inline-block;  
  position: relative;
  padding-left: 20px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  line-height: 17px;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border: 1px solid #1CF9CF;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover .checkmark {
  background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #1CF9CF;
  border: 1px solid #eee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}